import React from "react";
import './underlines.less'

export const UnderlineSm = ()=>{
    return(
        <div className="underline-container">
            <div className="underline-one"></div>
            <div className="underline-two"></div>
            <div className="underline-one"></div>
        </div>
    )
}

export const Underlines = ()=>{
    return(
        <div className="underlines">
            <div className="underline-one"></div>
            <div className="underline-two"></div>
            <div className="underline-one"></div>
        </div>
    )
}
