import React from 'react';
import {useEffect, useState} from 'react';
import { Underlines } from '../Underlines/Underlines';

import "./review.less" 


export default () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://apps.elfsight.com/p/platform.js";
        script.defer = true;
        script.async = false;

        document.body.appendChild(script);
    }, [])
    return(
        <section className="car-reviews" id="reviews">
            {/* <Underlines/> */}
            <h1>Nuestras muchas clientas felices</h1>
            <div className="underline-lg-container">
                <div className="underline-lg-one"></div>
                <div className="underline-lg-two"></div>
                <div className="underline-lg-one"></div>
            </div>
            <div className="elfsight">
                <div className="elfsight-app-6b83596d-1a4b-41ff-9f76-24e47edef30b sm"></div>
                <div className="elfsight-app-7cab10e1-77e9-413b-a40a-04aef1bca7d2 md"></div>
            </div>
        </section>
    )
}