import React from 'react'
import './guidance.less';
import { Underlines } from '../Underlines/Underlines';

export default () => {
  return (
    <section className="guidance">
        <Underlines/>
        <article className="container guidance-container">
            <div className="guidance-wrapper">
                <h2>
                    Orientación De Casos GRATIS, A Solo 1 Paso.
                </h2>
                <p>
                    ¡Actúa rápido! No pierda la oportunidad de reclamar la recuperación que necesita por sus lesiones.
                </p>
                <a href="tel:212-365-4255" className='btn'>
                    Obtenga su consulta GRATIS ahora 
                    <span>
                        212-365-4255
                    </span>
                </a>
            </div>
        </article>
    </section>
  )
}
